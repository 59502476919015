<template>
  <div class="check-ranking flex-column">
    <div class="nameTit flex-space-between">
      <div class="tp-left flex-center-v">
        <span style="margin-right: 10px;">应用统计</span>
      </div>
      <div class="tp-right flex-center-v">
        <el-date-picker
            size="small"
            v-model="timer"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            style="width: 400px;margin-right: 10px;">
        </el-date-picker>
        <el-select v-model="searchData.param" placeholder="施工状态" size="small" style="width:160px;margin-right:15px;" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option label="在施" :value="0"></el-option>
          <el-option label="完工" :value="1"></el-option>
        </el-select>
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
    </div>
    <div class="one flex">
      <div class="oneItem">
        <div>使用项目数</div><span style="color: #5C77E3;">{{ details.useCount || 0 }}</span>
      </div>
      <div style="margin: 0 5px;border-right: 1px dashed #dcdcdc;height: 100%;"></div>
      <div class="oneItem">
        <div>未使用项目数</div><span style="color: #F3974F;">{{ details.notUseCount || 0 }}</span>
      </div>
      <div style="margin: 0 5px;border-right: 1px dashed #dcdcdc;height: 100%;"></div>
      <div class="oneItem">
        <div>项目应用率</div><span style="color: #49BFAC;">{{ details.rate || 100 }}%</span>
      </div>
      <div style="margin: 0 5px;border-right: 1px dashed #dcdcdc;height: 100%;"></div>
      <div class="oneItem">
        <div>项目总数</div><span style="color: #691FC5;">{{ details.totalCount || 0 }}</span>
      </div>
    </div>
    <div class="cont flex-1-auto flex">
      <el-table ref="multipleTable" :data="details.dtoList" height="100%" style="width: 100%" :header-cell-style="{background:'#f2f2f2',color:'#333333'}">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="name" label="企业/项目名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="useCount" label="使用项目" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="notUseCount" label="未使用项目" align="center"></el-table-column>
        <el-table-column prop="rate" label="项目应用率（%）" align="center">
          <template slot-scope="{row}">
            <span>{{ row.rate }}%</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import apis from "@/views/menus/system-setup/components/apis3";

export default {
  name: "check-ranking",

  components: {},

  props: {},

  data() {
    return {
      details: {
        dtoList: [],
        notUseCount: '',
        rate: '',
        totalCount: '',
        useCount: ''
      },
      timer: '',
      searchData: {
        checkStartDate: '',
        checkEndDate: '',
        param: ''
      },
    }
  },

  computed: {},

  watch: {},

  mounted() {
    this.getTableList().async
  },

  methods: {
    async getTableList() {
      const result = await apis.projectStatistics(this.searchData)
      this.details = result
    },
    // 查询
    search() {
      if (this.timer) {
        this.searchData.checkStartDate = this.timer[0]
        this.searchData.checkEndDate = this.timer[1]
      } else {
        this.searchData.checkStartDate = ''
        this.searchData.checkEndDate = ''
      }
      this.getTableList().async
    },
  }
}
</script>

<style lang="stylus" scoped>
.check-ranking {
  height 100%
  padding 10px
  .one {
     padding 30px 0
    .oneItem {
      width 25%
      display flex
      flex-direction column
      > div {
        margin-bottom 10px
      }
      >span {
        font-size 30px
      }
    }
  }
}
</style>
